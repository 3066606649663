<template>
  <modal
    :name="modalName"
    class="meeting-details-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">

    <div>
      <MeetingRemoveModal :show-modal="showMeetingRemoveModal" @removeMeetingClick="deleteMeeting" @cancelClick="showMeetingRemoveModal = false" />
      <MeetingRecurrentRemoveModal :show-modal="showMeetingRecurrentRemoveModal" @removeRecurrencesClick="deleteRecurrentMeetings" @removeMeetingClick="deleteMeeting" @cancelClick="showMeetingRecurrentRemoveModal = false" />

      <div class="modal-header-container">
        <VBtn icon @click="editMeeting" :disabled="disableEditButton"><VIcon>mdi-pencil</VIcon></VBtn>
        <VBtn icon @click="confirmDeleteMeeting" :disabled="disableDeleteButton"><VIcon>mdi-trash-can</VIcon></VBtn>
        <v-btn icon @click="destroyModal">
          <VIcon>mdi-close</VIcon>
        </v-btn>
      </div>

      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>

      <VCard v-if="meeting">
        <v-card-title class="d-flex align-center">
          <div><span>{{ meeting.name }}</span><span v-if="recurrentMeeting">(Recurrent)</span></div>
        </v-card-title>

        <VCardText>
          <VRow>
            <VCol>Id: {{meeting.id}}</VCol>
          </VRow>
          <VRow>
            <VCol cols="6">
              <VIcon class="mr-2">mdi-calendar-clock</VIcon> {{ formattedStartTime }}
            </VCol>
            <VCol cols="6">
              <VIcon class="mr-2">mdi-timer</VIcon> {{ meeting.length_hours }} hours
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="12">
              <VIcon class="mr-2">mdi-video</VIcon> {{ meeting.sub_type }}
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="12">
              <VIcon class="mr-2">mdi-link</VIcon>{{ meeting.zoom_join_info }}
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="12">
              <VIcon class="mr-2">mdi-link</VIcon><a :href="meeting.join_url" target="_blank">Join Url</a>
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="6">
              <VIcon class="mr-2">mdi-clock-outline</VIcon> Created at: {{ formattedCreatedAt }}
            </VCol>
            <VCol cols="6">
              <VIcon class="mr-2">mdi-clock-outline</VIcon> Updated at: {{ formattedUpdatedAt }}
            </VCol>
          </VRow>

          <VRow v-if="shouldShowBreakoutRooms">
            <VCol cols="12">
              <v-expansion-panels accordion v-model="breakoutRoomsExpansionPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div>
                      <span>Breakout Rooms</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(room, index) in meeting.settings.breakout_rooms" :key="index">
                      <div>{{ room }}</div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </VCol>
          </VRow>

          <VRow v-if="delegationsTable.allItems.length > 0">
            <VCol cols="12">
              <v-expansion-panels accordion v-model="delegationsExpansionPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div>
                      <span>Delegations</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                        :headers="delegationsTable.headers"
                        :items="delegationsTable.allItems"
                        item-key="id"
                        :page="delegationsTable.tablePagination.currentPage"
                        :items-per-page="delegationsTable.perPage"
                        :server-items-length="delegationsTable.tablePagination.total"
                        :sort-by.sync="delegationsTable.sortColumn"
                        :sort-desc.sync="delegationsTable.sortDesc"
                        @update:page="handlePageChange"
                        @update:sort-by="handleSortColumn"
                        @update:sort-desc="handleSortDirection"
                        :footer-props="{'items-per-page-options': [delegationsTable.perPage]}"
                    >
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </VCol>
          </VRow>

        </VCardText>
      </VCard>
    </div>
  </modal>
</template>

<script>
  import ModalService from "@services/ModalService";
  import ModalMixin from '@mixins/ModalMixin.vue'
  import FlashMessages from '@services/FlashMessageService'
  import axios from "@dataServices/axios";
  import { VAlert, VDataTable, VCard, VCardTitle, VCardText, VCardActions, VBtn, VIcon, VRow, VCol, VExpansionPanel, VExpansionPanelContent, VExpansionPanels, VExpansionPanelHeader } from 'vuetify/lib'
  import dayjs from "dayjs";
  import EventBus from "@services/GlobalEventBus";
  import MeetingRemoveModal from "@components/backoffice/meeting_management/MeetingRemoveModal.vue";
  import MeetingRecurrentRemoveModal from "@components/backoffice/meeting_management/MeetingRecurrentRemoveModal.vue";

  const NAME = "meeting-details-modal"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      MeetingRemoveModal,
      MeetingRecurrentRemoveModal,
      VAlert,
      VDataTable,
      VExpansionPanel,
      VExpansionPanelContent,
      VExpansionPanels,
      VExpansionPanelHeader,
      VCard,
      VCardTitle,
      VCardText,
      VCardActions,
      VBtn,
      VIcon,
      VRow,
      VCol,
    },
    data() {
      return {
        modalName: NAME,
        meetingId: this.params.meetingId,
        recurrentMeeting: null,
        meeting: null,
        delegations: [],
        breakoutRoomsExpansionPanel: false,
        delegationsExpansionPanel: false,
        showMeetingRemoveModal: false,
        showMeetingRecurrentRemoveModal: false,
        delegationsTable: {
          headers: [
            { text: 'Id', value: 'id', sortable: false },
            { text: 'Module Id', value: 'module_id', sortable: false },
            { text: 'Module Name', value: 'module_name', sortable: false },
            { text: 'Program Id', value: 'program_id', sortable: false },
            { text: 'Program Name', value: 'program_name', sortable: false },
          ],
          allItems: [],
          tablePagination: {
            total: 0,
            currentPage: 1,
            totalPages: 0,
          },
          perPage: 5,
          sortColumn: ['updated_at'],
          sortDesc: [true],
        },
        unsubscribeListenerFunctions: [],
        errorMessage: '',
        alertVisible: false,
      }
    },
    async mounted () {
      await this.fetchMeeting()
      this.unsubscribeListenerFunctions = [
        EventBus.subscribe('meeting-upserted', async (data) => await this.handleMeetingUpserted(data))
      ]
    },
    beforeDestroy() {
      this.unsubscribeListenerFunctions.forEach((unsubscribe) => unsubscribe());
    },
    computed: {
      shouldShowBreakoutRooms() {
        return this.meeting?.settings?.breakout_rooms?.length > 0;
      },
      disableEditButton(){
        return this.meeting && (this.isPastMeeting(this.meeting) || this.recurrentMeeting);
      },
      disableDeleteButton(){
        return this.meeting && this.isPastMeeting(this.meeting);
      },
      formattedStartTime() {
        return dayjs(this.meeting.start_time).format('dddd, MMMM D - h:mma'); //M/D/YY h:mma
      },
      formattedCreatedAt() {
        return dayjs(this.meeting.created_at).format('MMMM D h:mma'); //M/D/YY h:mma
      },
      formattedUpdatedAt() {
        return dayjs(this.meeting.updated_at).format('MMMM D h:mma'); //M/D/YY h:mma
      },
    },
    methods: {
      async handleMeetingUpserted(data) {
        await this.fetchMeeting();
      },
      isPastMeeting(meeting) {
        const now = dayjs();
        return dayjs(meeting.start_time).isBefore(now)
      },
      async fetchMeeting () {
        try {
          const response = await axios.get(baseUrl + `/api/v2/bff/meeting_management/meeting_details/init_data`, { params: { meeting_id: this.meetingId } });
          this.recurrentMeeting = response.data.recurrent_meeting;
          this.meeting = response.data.meeting;
          this.delegations = response.data.delegations;
          this.updateTableData();
        } catch (error) {
          console.error("Failed to fetch meeting:", error);
          FlashMessages.error("Failed to fetch meeting! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      updateTableData() {
        const { sortColumn, sortDesc, perPage, tablePagination } = this.delegationsTable;

        // Ensure that we have delegations to process
        if (!this.delegations || this.delegations.length === 0) {
          this.delegationsTable.allItems = [];
          this.delegationsTable.tablePagination.total = 0;
          this.delegationsTable.tablePagination.totalPages = 0;
          return;
        }


        // Sorting the delegations based on the current sort settings
        const sortedItems = [...this.delegations].sort((a, b) => {
          const valA = a[sortColumn[0]];
          const valB = b[sortColumn[0]];

          // Handle null or undefined values in sorting
          if (valA === null || valA === undefined) return 1;
          if (valB === null || valB === undefined) return -1;

          // Sorting based on ascending or descending order
          if (sortDesc[0]) {
            return valA < valB ? 1 : -1;
          } else {
            return valA > valB ? 1 : -1;
          }
        });

        // Paginate the sorted items
        const startIndex = (tablePagination.currentPage - 1) * perPage;
        const paginatedItems = sortedItems.slice(startIndex, startIndex + perPage);

        // Update the table with paginated items and total count
        this.delegationsTable.allItems = paginatedItems;
        this.delegationsTable.tablePagination.total = this.delegations.length;
        this.delegationsTable.tablePagination.totalPages = Math.ceil(this.delegations.length / perPage);
        this.delegationsTable.tableLoading = false;
      },
      handleSortColumn(newSortColumn) {
        this.delegationsTable.sortColumn = [newSortColumn];
        this.updateTableData();
      },
      handleSortDirection(newSortDirection) {
        this.delegationsTable.sortDesc = [newSortDirection];
        this.updateTableData();
      },
      handlePageChange(newPage) {
        this.delegationsTable.tablePagination.currentPage = newPage;
        this.updateTableData();
      },
      editMeeting() {
        ModalService.open('meeting-upsert-modal', {recurrentMeeting: this.recurrentMeeting, meeting: this.meeting, delegations: this.delegations});
      },
      confirmDeleteMeeting() {
        if (this.recurrentMeeting) {
          this.showMeetingRecurrentRemoveModal = true;
        } else {
          this.showMeetingRemoveModal = true;
        }
      },
      async deleteRecurrentMeetings() {
        try {
          const response = await axios.delete(baseUrl + `/api/v2/bff/meeting_management/meeting_details/delete_recurrent_meeting`, { params: { meeting_id: this.meetingId } });
          EventBus.dispatch("meeting-recurrent-deleted", { ids: response.data.deleted_recurrent_meetings.map(meeting => meeting.id) })
          this.destroyModal();
        } catch (error) {
          this.errorMessage = error.response? error.response.data.message : error.message;
          this.alertVisible = true;
          console.error("Failed to delete recurrent meetings:", error);
        }
      },
      async deleteMeeting() {
        try {
          const response = await axios.delete(baseUrl + `/api/v2/bff/meeting_management/meeting_details/delete_meeting`, { params: { meeting_id: this.meetingId } });
          EventBus.dispatch("meeting-deleted", { id: this.meeting.id })
          this.destroyModal();
        } catch (error) {
          this.errorMessage = error.response? error.response.data.message : error.message;
          this.alertVisible = true;
          console.error("Failed to delete meeting:", error);
        }
      }
    },
  }
</script>

<style lang="scss">
  .meeting-details-modal .v-card {
    padding: 16px;
  }

  .meeting-details-modal .v-card-title {
    font-size: 20px;
    font-weight: bold;
  }

  .meeting-details-modal .v-icon {
    vertical-align: middle;
  }

  .meeting-details-modal a {
    color: #1976d2;
    text-decoration: none;
  }

  .meeting-details-modal a:hover {
    text-decoration: underline;
  }

  .meeting-details-modal .modal-header-container .v-btn {
    margin-left: 8px;
  }

</style>
